import React from "react";
import { rgba } from "polished";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span, Box } from "../../components/Core";
import OrderForm from "../../components/OrderForm";
import { device } from "../../utils";

const ContactCard = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => rgba(theme.colors.contactBackground, 0.1)};
  margin-top: 3rem;
  @media ${device.lg} {
    margin-top: 150px;
  }
`;

const Order = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="justify-content-center">
            <Col lg="6">
              <Box className="pr-lg-5">
                <Text color="light" mb="2.75rem">
                  Опишите как можно подробнее что хотите видеть: цвет, размер а так же любые другие пожелания по изделию
                </Text>
                <OrderForm theme="white" />
              </Box>
            </Col>
            <Col lg="5">
              <ContactCard className="p-5 ml-lg-5">
                <div>
                  <Text color="light">Моя почта</Text>

                  <a href="mailto:tatsiana@tlknitting.com" className="font-weight-bold">
                    <Span color="white">tatsiana@tlknitting.com</Span>
                  </a>
                </div>
              </ContactCard>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Order;
